import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
	AnonGuard,
	AuthGuard,
	ConfirmEmailComponent,
	LoginComponent,
	LogoutComponent,
	RegisterComponent,
	RequestResetPasswordComponent,
	ResetPasswordComponent
} from '@illuminaudio/core-web';
import { AboutComponent } from './about/about.component';
import { AccountComponent } from './account/account.component';
import { AdminComponent } from './admin/admin.component';
import { ContactComponent } from './contact/contact.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
	{
		path: 'home',
		component: HomeComponent,
		data: { title: 'Home', icon: 'home' }
	},
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		data: { title: 'Dashboard', icon: 'dashboard' }
	},
	{
		path: 'account',
		component: AccountComponent,
		canActivate: [AuthGuard],
		data: { title: 'Account', icon: 'account_circle' }
	},
	{
		path: 'register',
		component: RegisterComponent,
		canActivate: [AnonGuard],
		data: { title: 'Register', icon: 'person_add' }
	},
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [AnonGuard],
		data: { title: 'Login', icon: 'login' }
	},
	{
		path: 'logout',
		component: LogoutComponent,
		data: { title: 'Logout', icon: 'logout' }
	},
	{
		path: 'confirm-email',
		component: ConfirmEmailComponent,
		canActivate: [AnonGuard],
		data: { title: 'Confirm Email', icon: 'mark_email_read' }
	},
	{
		path: 'request-reset-password',
		component: RequestResetPasswordComponent,
		canActivate: [AnonGuard],
		data: { title: 'Reset Password', icon: 'lock_reset' }
	},
	{
		path: 'reset-password',
		component: ResetPasswordComponent,
		canActivate: [AnonGuard],
		data: { title: 'Reset Password', icon: 'lock_reset' }
	},
	{
		path: 'admin',
		component: AdminComponent,
		canActivate: [AuthGuard],
		data: { title: 'Admin', icon: 'settings' }
	},
	{
		path: 'contact',
		component: ContactComponent,
		data: { title: 'Contact', icon: 'email' }
	},
	{
		path: 'about',
		component: AboutComponent,
		data: { title: 'About', icon: 'info' }
	},
	{ path: '', redirectTo: '/home', pathMatch: 'full' },
	{ path: '**', redirectTo: '/home' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
