import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreWebMaterialModule } from '@illuminaudio/core-web';
import { AccountComponent } from './account.component';

@NgModule({
	declarations: [AccountComponent],
	imports: [CommonModule, CoreWebMaterialModule],
	exports: [AccountComponent]
})
export class AccountModule {}
