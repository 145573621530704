import { Component } from '@angular/core';
import { TitleService } from '@illuminaudio/core-web';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent {

	constructor(private titleService: TitleService) {
	}

	ngOnInit(): void {
		this.titleService.initialize('Tradescout').then(
			result => {
				this.titleService.setTitle();
				console.log('initialized title', result);
			},
			error => console.error('error initializing title', error)
		);

	}
}
