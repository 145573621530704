import { Component, OnInit } from '@angular/core';
import { AuthService, UserService } from '@illuminaudio/core-web';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
	constructor(public authService: AuthService, private userService: UserService) {}

	ngOnInit() {
		console.log('account init');
		this.userService.getUser().subscribe(
			(user) => console.log('account init user', user),
			(error) => console.log('account init error', error)
		);
	}
}
