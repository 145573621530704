import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreWebMaterialModule } from '@illuminaudio/core-web';
import { DashboardComponent } from './dashboard.component';

@NgModule({
	declarations: [DashboardComponent],
	imports: [CommonModule, CoreWebMaterialModule],
	exports: [DashboardComponent]
})
export class DashboardModule {}
