import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AuthInterceptor,
  AuthService,
  ConfigService,
  CoreWebAuthModule,
  CoreWebMaterialModule,
  CoreWebModule,
  CoreWebServicesModule,
  CoreWebSidenavModule,
  SpinnerInterceptor,
  TitleService
} from '@illuminaudio/core-web';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AboutModule } from './about/about.module';
import { AccountModule } from './account/account.module';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactModule } from './contact/contact.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { HomeModule } from './home/home.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    LayoutModule,
    HttpClientModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreWebAuthModule,
    CoreWebMaterialModule,
    CoreWebModule,
    CoreWebServicesModule,
    CoreWebSidenavModule,
    NgxCaptchaModule,
    NgxSpinnerModule,
    AppRoutingModule,
    AboutModule,
    AccountModule,
    AdminModule,
    ContactModule,
    DashboardModule,
    HomeModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [ConfigService],
      useFactory: (configService: ConfigService) => {
        return (): Promise<void> => configService.initialize();
      },
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      deps: [TitleService],
      useFactory: (titleService: TitleService) => {
        return (): Promise<void> => titleService.initialize();
      },
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      deps: [AuthService],
      useFactory: (authService: AuthService) => {
        return (): Promise<void> => authService.initialize();
      },
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' }
    },
    ConfigService,
    Title,
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
