<div class="auth-page">
	<mat-card class="auth-card">
		<mat-card-content>
			<div>
				<div class="auth-card-message">
					{{ authService?.user?.email }}
				</div>
			</div>
		</mat-card-content>
	</mat-card>
</div>
